<template>
    <div id="page-content" class="rich_media_area_primary">
        <Head ref="head"></Head>
        <section>
            <div class="mark content-block five-block">
                <p class="service-info-title" style="padding: 0px 335px;">
                    {{ type == 1 ? '行业专家库' : type == 2 ? '行业服务商资源池' : "新闻列表"}}
                </p>
                <div v-if="type == 1" class="service-box" style="background: none;">
                    <div class="service-inner" style="width: 1230px;">
                        <div
                                v-for="(item, index) in chemicalPeopleList"
                                :key="index"
                                style="display:flex;justify-content:center;width: 234.6px;margin-right: 10px;"
                        >
                           <!-- <div class="single-swiper" @click="peopleClick(item.id)"> -->
						    <div class="single-swiper" >
								<div style="width: 208px; height: 264px;text-align:center;line-height:264px"> <img :src="item.phoneUrl" alt=""  style="display:inline-block;vertical-align:middle; width:208px;height:80px;"/></div>
								
                                <!-- <img :src="item.phoneUrl" alt=""/> -->
                                <div class="swiper-name">{{ item.name }}</div>
                                <div class="synopsis">
                                    {{ item.synopsis }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="type == 2" class="service-box" style="height: auto;">
                    <div class="service-inner" style="height: auto;">
                        <div v-for="(list, index) in catholicismImgList"
                             :key="index"
                             class="el-car-item" style="height: auto;margin-bottom: 50px;">
                           <!-- <div
                                    v-for="item in list"
                                    class="service-item"
                                    @click="serviceClick(item.id)"
                            > -->
							<div
							        v-for="item in list"
							        class="service-item"
							        
							>
                                <div style="display:flex;">
                                    <span class="gray-dot"></span>
                                    <span class="svc-title" :title="item.enterpriseName">{{
                        item.enterpriseName
                      }}</span>
                                </div>
                                <div class="svc-content">
                                    {{ item.enterprisSynopsis }}
                                </div>
                                <div class="tag-list">
                                    <div
                                            v-for="(el, index) in item.enterprisType"
                                            class="single-tag"
                                            :class="sstt[index]"
                                            :title="el"
                                    >
                                        {{ el }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--<el-carousel
                                indicator-position="none"
                                arrow="never"
                                :interval="5000"
                                style="width: 1180px;height:500px"
                        >
                          <el-carousel-item
                                  v-for="(list, index) in catholicismImgList"
                                  :key="index"
                                  class="el-car-item"
                          >
                            <div
                                    v-for="item in list"
                                    class="service-item"
                                    @click="serviceClick(item.id)"
                            >
                              <div style="display:flex;">
                                <span class="gray-dot"></span>
                                <span class="svc-title" :title="item.enterpriseName">{{
                                    item.enterpriseName
                                  }}</span>
                              </div>
                              <div class="svc-content">
                                {{ item.enterprisSynopsis }}
                              </div>
                              <div class="tag-list">
                                <div
                                        v-for="(el, index) in item.enterprisType"
                                        class="single-tag"
                                        :class="sstt[index]"
                                        :title="el"
                                >
                                  {{ el }}
                                </div>
                              </div>
                            </div>
                          </el-carousel-item>
                        </el-carousel>-->
                    </div>
                </div>
                <div v-if="type == 3">
                    <div style="width: calc(100% - 800px);;margin: 50px auto;min-width: 500px;max-width: 800px;">
                        <div v-for="(item, index) in newsList" style="display: flex;justify-content: space-between;cursor: pointer;height: 37.5px;line-height: 37.5px;"
                             @click="newsDetail(item.id)">
                            <span style="color: #000000">{{item.news}}</span>
                            <span style="color: #999999">{{item.createTime.split("T")[0]}}</span>
                        </div>
                    </div>
                    <!--<dv-scroll-board
                            @click="skipto"
                            :config="config"
                            class="datav-table"
                            style="height: 100%"
                    />-->
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import {get} from "../utils/request";
    import Head from "../views/components/HeadNoMenu.vue";

    export default {
        components: {
            Head,
        },
        data() {
            return {
                sstt: ["high", "Medium", "low"],
                type: 0,
                chemicalPeopleList: [],
                catholicismImgList: [],
                newsList: [],
                config: {
                    oddRowBGC: "#F4F4F4",
                    evenRowBGC: "#F4F4F4",
                    waitTime: 10 * 1000,
                    align: "left",
                    rowNum: 8,
                    data: [],
                    columnWidth: [700, 120],
                    carousel: "single",
                },
            };
        },
        mounted() {
            this.type = localStorage.type;
            if (this.type == 1) {
                this.getChemicalPeople();
            } else if (this.type == 2) {
                this.getChemicalService();
            } else if (this.type == 3) {
                this.getNewsList();
            }
        },
        methods: {
            // 化工专家
            getChemicalPeople() {
                get("/api/getChemicalPeople",{isMore:1}).then((res) => {
                    if (res.status == 200) {
                        this.chemicalPeopleList = res.data.data;
                        console.log("chemicalPeopleList");
                        console.log(this.chemicalPeopleList);
                    }
                });
            },
            // 化工服务商
            getChemicalService() {
                get("/api/getChemicalService",{isMore:1}).then((res) => {
                    if (res.status == 200) {
                        let list = res.data.data;
                        if (list.length > 0) {
                            list.map((el) => {
                                el.enterprisType = el.enterprisType.split(",");
                                el.enterprisType = el.enterprisType.splice(0, 3);
                            });
                            this.chemicalServiceList = list;
                            this.catholicismImgList = this.setImgData(list, 9999);
                            console.log(this.catholicismImgList);
                        }
                    }
                });
            },
            //新闻列表
            getNewsList() {
                get("/api/getNewsList",{isMore:1}).then((res) => {
                    if (res.status == 200) {
                        this.newsList = res.data.data;
                        console.log("newsList:");
                        console.log(this.newsList);
                        if (this.newsList.length > 0) {
                            // 1、定义正则规则
                            let re1 = new RegExp("<.+?>", "g");
                            this.newsList.map((el) => {
                                // 2、将富文本编辑器生成的文本进行替换
                                this.$set(el, "content", el.newsContent.replace(re1, ""));
                            });
                            this.newsItem = this.newsList.shift();
                            console.log(this.newsItem, "newsItem");
                        } else {
                            return;
                        }
                    }
                });
            },
            // @param oriList原数组 groupNum每组数量
            setImgData(oriList, groupNum) {
                let newDataList = [];
                let current = 0;
                if (oriList && oriList.length > 0) {
                    for (let i = 0; i <= oriList.length - 1; i++) {
                        if (i % groupNum !== 0 || i === 0) {
                            if (!newDataList[current]) {
                                newDataList.push([oriList[i]]);
                            } else {
                                newDataList[current].push(oriList[i]);
                            }
                        } else {
                            current++;
                            newDataList.push([oriList[i]]);
                        }
                    }
                }
                return [...newDataList];
            },
            serviceClick(id) {
                localStorage.serviceId = id;
                const routeData = this.$router.resolve({
                    name: "detailService",
                });
                window["open"](routeData.href, "_blank");
            },
            peopleClick(id) {
                localStorage.peopleId = id;
                const routeData = this.$router.resolve({
                    name: "detailPeople",
                });
                window["open"](routeData.href, "_blank");
            },
            newsDetail(id) {
                localStorage.newsId = id;
                const routeData = this.$router.resolve({
                    name: "detail",
                });
                window["open"](routeData.href, "_blank");
            },
        },
    };
</script>

<style lang="less" scoped>
    // 主体一
    .slideBox {
        width: 100%;
        overflow: hidden;
        position: relative;
        margin-bottom: 56px;
    }

    .content-block.service-idea {
        // background-color: #f5f5f5;
        width: 100%;
        max-width: 1500px;
        margin: 0 auto;
        text-align: center;
    }

    .content-block.service-idea .service-idea {
        max-width: 1500px;
        overflow: hidden;
        display: flex;
    }

    .content-block.service-idea .idea-left {
        img {
            width: 580px;
            height: 177px;
            border-radius: 0px 0px 0px 0px;
            opacity: 1;
        }
    }

    .content-block.service-idea .idea-right {
        height: 300px;
        width: calc(100% - 600px);
        margin-left: 100px;
    }

    .content-block.service-idea .idea-right .block-line {
        margin: 0 0 12px 0;
        width: 24px;
        height: 4px;
        background-color: #3370ff;
        border-radius: 100px;
    }

    .content-block.service-idea .idea-right .idea-title {
        font-weight: 700;
        font-size: 28px;
        position: relative;
        bottom: 2px;
        color: #000;
    }

    .content-block.service-idea .idea-right .idea-subtitle {
        font-size: 16px;
        line-height: 1;
        color: #646a73;
        margin-top: 10px;
    }

    .content-block.service-idea .idea-right img {
        width: 500px;
        height: 200px;
        -o-object-fit: cover;
        object-fit: cover;
        padding: 10px;
        border: 2px dashed #d3dce6;
    }

    .content-block.service-idea .idea-right .idea-desc {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        overflow: hidden;
        max-height: 118px;
        max-width: 850px;
        font-size: 14px;
        line-height: 30px;
        color: #646a73;
    }

    .pointer14 {
        cursor: pointer;
        font-size: 14px;
    }

    // 化工企业
    .wrap-block .content-block {
        position: relative;
        overflow: hidden;
    }

    .service-block {
        max-width: 1440px;
        margin: 0 auto;
        text-align: center;
    }

    .five-block {
        width: 100%;
        margin: 0 auto;
        text-align: center;
    }

    .block-left {
        width: 36px;
        height: 6px;
        background-color: #3370ff;
        // border-radius: 100px;
    }

    .news-title {
        margin: 36px 0;
        height: 30px;
        font-size: 20px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: bold;
        color: #333333;
        line-height: 30px;
        text-align: left;
    }

    .news-content {
        width: 580px;
        height: 74px;
        font-size: 14px;
        font-family: Source Han Sans CN-Normal, Source Han Sans CN;
        font-weight: 400;
        color: #545454;
        line-height: 18px;
        // 溢出隐藏
        overflow: hidden;
        // 显示省略号
        text-overflow: ellipsis;
        // 和-webkit-line-clamp结合使用
        // 将对象作为弹性伸缩盒子模型显示
        display: -webkit-box;
        // 用来限制在一个块元素显示的文本的行数，
        // 它需要组合其他的 WebKit 属性
        // 写什么数字表示最多显示几行。
        -webkit-line-clamp: 4;
        // 和-webkit-line-clamp结合使用
        // 设置伸缩盒对象的子元素的排列方式
        -webkit-box-orient: vertical;
        text-align: left;
        margin: 19px 0 34px;
    }

    .more-button {
        width: 106px;
        height: 37px;
        line-height: 37px;
        background: #235ae8;
        border-radius: 55px 55px 55px 55px;
        opacity: 1;
        font-size: 14px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        // font-weight: bold;
        color: #ffffff;
        cursor: pointer;
        text-align: center;
    }

    .mb20 {
        margin-bottom: 20px;
    }

    .newsList-title {
        text-align: left;
        height: 30px;
        font-size: 20px;
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-weight: 500;
        color: #333333;
        font-weight: bold;
        line-height: 23px;
        margin: 40px 0 35px 14px;
    }

    .block-line {
        margin: 0 auto 12px;
        width: 24px;
        height: 4px;
        background-color: #3370ff;
        border-radius: 100px;
    }

    .service-info-title {
        font-weight: 700;
        font-size: 28px;
        position: relative;
        bottom: 2px;
        color: #000;
    }

    .service-info-subtitle {
        font-size: 16px;
        line-height: 1.5;
        color: #646a73;
        margin-top: 30px;
    }

    .service-list {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        text-align: center;
        margin: 70px auto 50px auto;
        display: flex;
        background: url("../assets/img/five_bg.png") no-repeat;
        height: 398px;
        background-size: cover;
    }

    .service-title-shadow {
        width: 124px;
        height: 124px;
        cursor: pointer;
        transition: all 0.5s; /* 所有的属性变化在0.5s的时间段内完成 */
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .bgFusion {
        background: url(../assets/img/Fusion.png) no-repeat;
    }

    .bgWisePark {
        background: url(../assets/img/WisePark.png) no-repeat;
    }

    .bgChemicalEnterprise {
        background: url(../assets/img/ChemicalEnterprise.png) no-repeat;
    }

    .bgFusion:hover {
        background: url(../assets/img/Fusion_a.png) no-repeat !important;
    }

    .bgWisePark:hover {
        background: url(../assets/img/WisePark_a.png) no-repeat !important;
    }

    .bgChemicalEnterprise:hover {
        background: url(../assets/img/ChemicalEnterprise_a.png) no-repeat !important;
    }

    // .service-title-shadow :hover {
    //   background: #235ae8 !important;
    // }
    .service-title-shadow img:hover {
        transform: scale(1.05); /* 鼠标放到图片上的时候图片按比例放大1.5倍   */
    }

    .service-block .news-card {
        padding-top: 40px;
        margin: 0 auto 50px auto;
        display: flex;
        justify-content: space-evenly;
    }

    .service-block .news-card .news-card-item {
        width: 590px;
        // height: 255px;
        position: relative;
        cursor: pointer;

        .rich {
            height: 300px;
            overflow: hidden;
        }
    }

    .news-card-item img:hover {
        transform: scale(1.05); /* 鼠标放到图片上的时候图片按比例放大1.5倍   */
    }

    .news-card-item img {
        cursor: pointer;
        transition: all 0.5s; /* 所有的属性变化在0.5s的时间段内完成 */
    }

    .swiperBox {
        margin: 10px 50px;
    }

    .service-name {
        margin-top: 30px;
        font-weight: 600;
        height: 24px;
        font-size: 16px;
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-weight: 500;
        color: #ffffff;
        line-height: 24px;
    }

    .swiper-button-prev {
        width: 25px;
        height: 18px;
    }

    .swiper-button-next {
        width: 25px;
        height: 18px;
    }

    .single-swiper {
        cursor: pointer;
        width: 208px;
        height: 376px;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-weight: 600;
        font-size: 20px;
        color: #2c2828;

        img {
            width: 208px;
            height: 264px;
        }

        .swiper-name {
            padding: 23px 0 10px;
            height: 24px;
            font-size: 16px;
            font-family: Source Han Sans CN-Medium, Source Han Sans CN;
            font-weight: 600;
            color: #000000;
            line-height: 24px;
            border-bottom: 1px solid #ffc52f;
        }

        .synopsis {
            padding-top: 4px;
            overflow: hidden; //超出隐藏
            text-overflow: ellipsis; //溢出用省略号显示
            display: -webkit-box; // 将对象作为弹性伸缩盒子模型显示。
            // 控制行数
            -webkit-line-clamp: 2; //超出两行隐藏
            -webkit-box-orient: vertical; // 从上到下垂直排列子元素
            font-size: 16px;
            font-weight: normal;
        }
    }

    .datav-table {
        ::v-deep .ceil {
            text-align: left;
            color: #000;
            cursor: pointer;
        }
    }

    /* @media screen and (min-width: 960px) and (max-width: 1200px) {
      .el-carousel__item {
        width: 1200px;
        background-color: #ccc;
      }
    } */
    .standard {
        width: 1200px;
        height: 266px;
        background: #f2f6ff;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        display: flex;
        text-align: left;

        img {
            width: 428px;
            height: 266px;
        }

        .standard_box {
            width: calc(100% - 428px);
            height: 100%;
            padding: 35px 65px 0 65px;
        }

        .standard_title {
            height: 27px;
            font-size: 18px;
            font-family: Source Han Sans CN-Bold, Source Han Sans CN;
            font-weight: bold;
            color: #000000;
            line-height: 27px;
            margin-bottom: 22px;
        }

        .standard_content {
            width: 642px;
            height: 124px;
            font-size: 14px;
            font-family: Source Han Sans CN-Normal, Source Han Sans CN;
            font-weight: 400;
            color: #545454;
            line-height: 18px;
            margin-bottom: 10px;
            // 溢出隐藏
            overflow: hidden;
            // 显示省略号
            text-overflow: ellipsis;
            // 和-webkit-line-clamp结合使用
            // 将对象作为弹性伸缩盒子模型显示
            display: -webkit-box;
            // 用来限制在一个块元素显示的文本的行数，
            // 它需要组合其他的 WebKit 属性
            // 写什么数字表示最多显示几行。
            -webkit-line-clamp: 7;
            // 和-webkit-line-clamp结合使用
            // 设置伸缩盒对象的子元素的排列方式
            -webkit-box-orient: vertical;
        }
    }

    .standard_1 {
        margin: 45px auto 0;
    }

    .standard_2 {
        margin: 20px auto 60px;
    }

    .expert-box {
        height: 400px;
        padding: 50px 0;
    }

    .service-inner {
        width: 1180px;
        height: 500px;
        display: flex;
        flex-wrap: wrap;
        text-align: left;
    }

    .service-box {
        margin: 50px 0;
        height: 525px;
        background: url("../assets/img/svc_bg.png") no-repeat;
        background-size: cover;

        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        display: flex;
        align-items: center;
        justify-content: center;

        .service-item {
            cursor: pointer;
            width: calc((100% - 210px) / 4);
            height: 180px;
            background: #ffffff;
            border-radius: 8px 8px 8px 8px;
            opacity: 1;
            margin-right: 26px;
            margin-top: 46px;
            padding: 12px;

            .gray-dot {
                width: 18px;
                height: 18px;
                background: #d9d9d9;
                opacity: 1;
                display: inline-block;
                border-radius: 100%;
                margin-top: 4px;
            }

            .svc-title {
                padding-left: 5px;
                height: 24px;
                font-size: 14px;
                font-family: Source Han Sans CN-Medium, Source Han Sans CN;
                font-weight: 500;
                color: #333333;
                line-height: 24px;
                width: calc(100% - 10px);
                // 溢出隐藏
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .svc-content {
                padding: 13px 0 13px 0;
                width: 236px;
                height: 75px;
                font-size: 14px;
                font-family: Source Han Sans CN-Normal, Source Han Sans CN;
                font-weight: 400;
                color: #333333;
                line-height: 18px;
                // 溢出隐藏
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                // 用来限制在一个块元素显示的文本的行数，
                // 它需要组合其他的 WebKit 属性
                // 写什么数字表示最多显示几行。
                -webkit-line-clamp: 5;
                // 和-webkit-line-clamp结合使用
                // 设置伸缩盒对象的子元素的排列方式
                -webkit-box-orient: vertical;
            }
        }

        .tag-list {
            margin-top: 12px;
            display: flex;
        }

        .single-tag {
            height: 22px;
            border-radius: 2px 2px 2px 2px;
            opacity: 1;
            text-align: center;
            margin-right: 5px;
            margin-bottom: 5px;
            padding: 0 8px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .high {
            border: 1px solid #ffc52f;
            color: #ffc52f;
        }

        .Medium {
            border: 1px solid #235ae8;
            color: #235ae8;
        }

        .low {
            border: 1px solid #ff932f;
            color: #ff932f;
        }
    }

    // 走马灯
    .el-car-item {
        height: 500px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }

    .detail-box {
        height: 194px;
        border-radius: 5px 5px 5px 5px;
        opacity: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        text-align: center;
        padding: 50px 0;

        .single-detail {
            width: 100px;
            height: 38px;
            background: #ffffff;
            border-radius: 4px 4px 4px 4px;
            opacity: 1;
            font-size: 20px;
            font-family: Source Han Sans CN-Medium, Source Han Sans CN;
            font-weight: 500;
            color: #235ae8;
            line-height: 38px;
            cursor: pointer;
        }

        .w136 {
            width: 136px !important;
        }
    }

</style>
